<template>
  <v-card>
    <v-card-title style="word-break:break-word;">Position: {{ detail.placement }}</v-card-title>
    <v-card-text>
      <p>Supervisor: {{ detail.superName }} ({{ detail.superEmail }})</p>
      <v-list v-if="detail.clockIn">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Latest Timeclock Activity</v-list-item-title>
            <v-list-item-subtitle>Clocked In {{ detail.clockIn }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ detail.clockOut.substring(0, 2) === '**' ? detail.clockOut : 'Clocked Out: ' + detail.clockOut }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" :color="color">{{ icon }}</v-icon>
              </template>
              <span>{{ text }}</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { stringFormatDateToday } from '@/helpers/formatters'

export default {
  props: {
    detail: {
      type: Object,
      required: true
    },
    mini: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  // components: {
  //   gauge: () => import('./bigGauge')
  // },
  setup (props) {
    const color = ref('')
    const icon = ref('')
    const text = ref('')
    watch(() => props.detail, ({ clockStatus }) => {
      switch (clockStatus) {
        case 'P':
          const dt = new Date()
          if (props.clockOut === '**You are still clocked in**') {
            icon.value = 'fal fa-exclamation-circle'
            color.value = 'warning'
            text.value = 'Waiting for clock-out to add to Banner'
          } else {
            icon.value = 'fal fa-question-circle'
            color.value = 'info'
            text.value = 'Has not been added to Banner; ' + (dt.getHours() < 6 ? 'will be added after 6am' : 'will be added to Banner within the hour, unless the timesheet is not in an editable state (i.e. you have already submitted it)')
          }
          break
        case 'R':
          icon.value = 'fal fa-times-circle'
          color.value = 'error'
          text.value = 'The timeclock shift was not added to Banner because ' + (props.clockOut === '**You forgot to clock out**' ? 'you forgot to clock out' : 'the timeclock time conflicted with time already on the timesheet, meaning you entered it manually before the script could add it.')
          break
        case 'C':
          icon.value = 'fal fa-check-circle'
          color.value = 'success'
          text.value = 'The timeclock shift was successfully added to Banner'
      }
    })

    return {
      icon,
      color,
      text,
      stringFormatDateToday
    }
  }
}
</script>
